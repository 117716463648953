body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.example-basic {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid rgba(0,0,0,.12);
  border-radius: .25rem
}

.example-input-element {
  position: relative
}

.example-input-element>p {
  font-size: 1rem;
  color: #999
}

.example-input-element .arrow {
  background-color: #000;
  width: .75rem;
  height: .75rem;
  position: absolute;
  top: 6rem;
  transform: rotate(45deg)
}

.example-input-element>input {
  z-index: 1;
  width: 7rem;
  height: 3rem;
  background-color: #000;
  border-radius: .25rem;
  color: #fff;
  text-align: center;
  border: 0;
  outline: 0;
  font-size: 2rem;
  margin: .5rem 0;
  box-shadow: rgba(0,0,0,.12) 0px 4px 20px 0px
}

.example-custom-cursor .custom-cursor {
  background-color: crimson;
  width: .75rem;
  height: .75rem;
  transform: rotate(45deg);
  margin-top: 3rem
}

.example-resize-observer {
  overflow: hidden;
  resize: horizontal
}

.example-vertical {
  flex-direction: row;
  justify-content: center
}

.example-vertical .box {
  width: 5rem;
  margin: 0 5rem
}

.example-vertical .box>p:first-child {
  font-size: 1rem;
  margin: 0;
  color: #999
}

.example-vertical .box>p:last-child {
  font-size: 2rem;
  font-weight: bold
}

.example-custom-styled {
  background-color: #000;
  padding: 2px
}

.example-custom-styled>p {
  color: wheat
}